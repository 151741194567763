import { FC } from 'react'
import { AutocompleteInput, Create, FileField, FileInput, SimpleForm } from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { municipalities } from '../../utils/municipalityCodes'
import { required } from '../../utils/validation'

const ObjectionsCreate: FC = () => (
  <Create
    actions={<ActionsToolbar i18n="resources.objections.create" />}
    title={<HeaderTitle i18n="resources.objections.create" />}
    redirect="show"
  >
    <SimpleForm>
      <AutocompleteInput
        source="gemeentecode"
        label="data.gemeente"
        choices={municipalities}
        fullWidth
        validate={required()}
      />
      <FileInput
        source="file"
        label="PDF"
        accept={{ 'application/pdf': ['.pdf'] }}
        validate={required()}
      >
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>
)

export default ObjectionsCreate

/* eslint-disable */
import { fetchUtils } from 'ra-core'
import simpleRestProvider from 'ra-data-simple-rest'
import {
  CreateParams, DataProvider, DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  UpdateManyParams,
  UpdateParams
} from 'react-admin'
import mocks from '../mocks'
import { GetParams } from '../mocks/mockProvider'
import { debug } from '../test'
import authToken from '../utils/authToken'
import { getEnvVariable } from '../utils/getEnvVariable'

/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 * That's not the most optimized way to store images in production, but it's
 * enough to illustrate the idea of data provider decoration.
 */
const convertToBase64 = blob => new Promise(async (resolve, reject) => {
  const reader = new FileReader()
  reader.onload = () => resolve(reader.result)
  reader.onerror = reject

  reader.readAsDataURL(blob.rawFile)
})

export const httpClient = (url: string, options: RequestInit = {}): Promise<{
  status: number;
  headers: Headers;
  body: string;
  json: any;
}> => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
    options.headers.set('accept-language', 'nl')
  }

  const user = {
    authenticated: true,
    token: authToken.getToken() || '',
  }

  if (!user.token || user.token === '') return Promise.reject({ status: 412 })

  return fetchUtils.fetchJson(url, { ...options, user })
}

export const getUrl = (): string => getEnvVariable('REACT_APP_SERVER_URL', '')

debug && console.log({ getUrl: getUrl() })

const dataProvider: DataProvider = simpleRestProvider(getUrl(), httpClient)

type ObjectionsParams = {
  id: string;
  description: string;
  gemeentecode: number;
  bezwaarid: number;
  file: {
    rawFile: File;
    src?: string;
    title?: string;
  };
  status: string;
};

const createPostFormData = (
  params: CreateParams<ObjectionsParams> | UpdateParams<ObjectionsParams>
) => {
  const formData = new FormData();
  params.data.file?.rawFile && formData.append("file", params.data.file.rawFile);
  params.data.description && formData.append("description", params.data.description);
  params.data.gemeentecode && formData.append("gemeentecode", `${params.data.gemeentecode}`);
  params.data.bezwaarid && formData.append("bezwaarid", `${params.data.bezwaarid}`);
  params.data.status && formData.append("status", params.data.status);

  return formData;
};

interface CreateManyParams {
  data: any[];
}

export const xxllncDataProvider: DataProvider = {
  ...dataProvider,
  get: async (resource: string) => httpClient(`${getUrl()}/${resource}`, {
    method: 'GET',
  }).then(({ json }) => ({ data: json })),
  getList: (resource: string, params: GetListParams) => {
    console.log('getList called successfully', { resource, params, url: getUrl() })
    return dataProvider.getList(resource, params)
  },
  create: (resource: string, params: CreateParams) => {
    console.log('create called successfully', { resource, params })
    if (resource === "objection") {
      const formData = createPostFormData(params);
      return httpClient(`${getUrl()}/${resource}`, {
          method: "POST",
          body: formData,
        })
        .then(({ json }) => ({ data: json }));
    }

    if (!params.data.icon) {
      return dataProvider.create(resource, params)
    }

    if (!(params.data.icon.rawFile instanceof File)) {
      throw new Error('The rawFile is not a File object.')
    }

    return convertToBase64(params.data.icon).then((icon: any) => dataProvider.create(resource, {
      ...params,
      data: {
        ...params.data,
        icon: icon.replace('data:image/svg+xml;base64,', ''),
      },
    }))
  },
  createMany: async (resource: string, params: CreateManyParams) => {
    debug && console.log('createMany called successfully', { resource, params })
    if (resource === 'organization') {
      return httpClient(`${getUrl()}/${resource}/createMany`, {
        method: 'POST',
        body: JSON.stringify({
          data: params.data.map(item => ({
            name: item.Naam,
            integrationKey: item.uuid,
            integrationType: 'crm',
          }))
        }),
      })
    }

    if (resource === 'app_instance') {
      return httpClient(`${getUrl()}/${resource}/createMany`, {
        method: 'POST',
        body: JSON.stringify({
          data: params.data.map(item => {
            const { appId, app_id, organizationId, organization_id, hostnames, ...csv } = item
            csv.appId = app_id || appId || null
            csv.organizationId = organization_id || organizationId || null
            csv.hostnames = hostnames.split(' ')
            return ({ ...csv })
          })
        }),
      })
    }

    return params.data.map(item => dataProvider.create(resource, {
      data: {
        ...item
      }
    }))
  },
  update: (resource: string, params: UpdateParams) => {
    if (params.data.icon && typeof params.data.icon != 'string') {
      if (params.data.icon.rawFile && !(params.data.icon.rawFile instanceof File)) {
        throw new Error('The rawFile is not a File object.')
      }

      return convertToBase64(params.data.icon).then((icon: any) => dataProvider.update(resource, {
        ...params,
        data: {
          ...params.data,
          icon: icon.replace('data:image/svg+xml;base64,', ''),
        },
      }))
    }

    return dataProvider.update(resource, params)
  },
}

export const mockDataProvider: DataProvider = {
  get: async (resource: string, params: GetParams) => mocks.mockProvider.get(resource, params),
  getList: async (resource: string, params: GetListParams) => mocks.mockProvider.getList(resource, params),
  getOne: (resource: string, params: GetOneParams) => mocks.mockProvider.getOne(resource, params),
  getMany: (resource: string, params: GetManyParams) => mocks.mockProvider.getMany(resource, params),
  create: (resource: string, params: CreateParams) => mocks.mockProvider.create(resource, params),
  update: (resource: string, params: UpdateParams) => mocks.mockProvider.update(resource, params),
  delete: (resource: string, params: DeleteParams) => mocks.mockProvider.delete(resource, params),
  getManyReference: (resource: string, params: GetManyReferenceParams) => mocks.mockProvider.getManyReference(resource, params),
  /* Below is not implemented yet */
  updateMany: (resource: string, params: UpdateManyParams) =>
    httpClient(`xxx/${resource}/${JSON.stringify(params.ids)}`).then(response => ({ data: response.json })),
  deleteMany: (resource: string, params: DeleteManyParams) =>
    httpClient(`xxx/${resource}/${JSON.stringify(params.ids)}`).then(response => ({ data: response.json }))
}

import UploadIcon from '@mui/icons-material/Upload'
import { FC, useState } from 'react'
import { Button } from 'react-admin'
import BulkImportDialog from './BulkImportDialog'

const BulkImportButton: FC = () => {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button label="resources.objections.bulkImport.title" onClick={handleClickOpen}>
        <UploadIcon />
      </Button>
      <BulkImportDialog open={open} onClose={handleClose} />
    </>
  )
}

export default BulkImportButton

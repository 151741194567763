import { ResponsiveBar } from '@nivo/bar'
import { FC } from 'react'
import { ObjectionOut, RevisionnOut } from '../../types/apiRequests'

interface Props {
  objections: ObjectionOut[] | undefined
  revisions: RevisionnOut[] | undefined
}

const RevisionsBar: FC<Props> = ({ objections, revisions }) => {
  const processData = () => {
    if (!objections || !revisions) return []

    const objectionsById = objections.reduce((acc, objection) => {
      acc[objection.bezwaarid] = objection.description
      return acc
    }, {} as Record<number, string>)

    const revisionsCount = revisions.reduce((acc, revision) => {
      const objectionDescription = objectionsById[revision.objection_id]
      if (objectionDescription) {
        // eslint-disable-next-line security/detect-object-injection
        acc[objectionDescription] = (acc[objectionDescription] || 0) + 1
      }
      return acc
    }, {} as Record<string, number>)

    return Object.keys(revisionsCount).map(objectionDescription => ({
      objection: objectionDescription,
      // eslint-disable-next-line security/detect-object-injection
      revisions: revisionsCount[objectionDescription],
    }))
  }

  const data = processData()

  return (
    <ResponsiveBar
      data={data}
      keys={['revisions']}
      indexBy='objection'
      margin={{ top: 50, right: 60, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={['#31679B']}
      defs={[
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: '#eed312',
          rotation: -45,
          lineWidth: 6,
          spacing: 10
        }
      ]}
      fill={[]}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]]
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 30,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 40,
        truncateTickAt: 0
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Revisies per bezwaar',
        legendPosition: 'middle',
        legendOffset: -40,
        truncateTickAt: 0
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      legends={[]}
      role='application'
      ariaLabel='Revisies per bezwaar'
      // barAriaLabel={e=>e.id+': '+e.formattedValue+' for objection: '+e.indexValue}
    />
  )
}

export default RevisionsBar

export const municipalityCodeToName = (id?: string) => {
  if (!id) return ''
  const municipality = municipalities.find((m) => m.id === id)
  return municipality?.name
}

export const municipalityNameToCode = (name: string) => {
  const municipality = municipalities.find((m) => m.name === name)
  return municipality?.id
}

export const municipalities = [
  { name: 'Wijdemeren', id: '1696' },
  { name: 'Alphen-Chaam', id: '1723' },
  { name: 'Midden-Delfland', id: '1842' },
  { name: 'Molenlanden', id: '1978' },
  { name: 'Overbetuwe', id: '1734' },
  { name: 'Utrechtse Heuvelrug', id: '1581' },
  { name: 'Goes', id: '0664' },
  { name: 'Gemert-Bakel', id: '1652' },
  { name: 'Steenbergen', id: '0851' },
  { name: 'Boekel', id: '0755' },
  { name: 'Papendrecht', id: '0590' },
  { name: 'Veendam', id: '0047' },
  { name: 'Westland', id: '1783' },
  { name: 'Leidschendam-Voorburg', id: '1916' },
  { name: 'Zaltbommel', id: '0297' },
  { name: 'Nijkerk', id: '0267' },
  { name: 'Leusden', id: '0327' },
  { name: 'Noord-Beveland', id: '1695' },
  { name: 'Woensdrecht', id: '0873' },
  { name: 'Haaksbergen', id: '0158' },
  { name: 'Simpelveld', id: '0965' },
  { name: 'Altena', id: '1959' },
  { name: 'Rijssen-Holten', id: '1742' },
  { name: 'Teylingen', id: '1525' },
  { name: 'Rotterdam', id: '0599' },
  { name: 'Berkelland', id: '1859' },
  { name: 'Wageningen', id: '0289' },
  { name: 'Winterswijk', id: '0294' },
  { name: 'Vaals', id: '0981' },
  { name: 'Eemsdelta', id: '1979' },
  { name: 'Montfoort', id: '0335' },
  { name: 'Houten', id: '0321' },
  { name: 'Kapelle', id: '0678' },
  { name: 'Oostzaan', id: '0431' },
  { name: '\'s-Hertogenbosch', id: '0796' },
  { name: 'Roosendaal', id: '1674' },
  { name: 'Noardeast-Fryslân', id: '1970' },
  { name: 'Harlingen', id: '0072' },
  { name: 'Nijmegen', id: '0268' },
  { name: 'Lelystad', id: '0995' },
  { name: 'De Ronde Venen', id: '0736' },
  { name: 'Heemskerk', id: '0396' },
  { name: 'Sint Anthonis', id: '1702' },
  { name: 'Dalfsen', id: '0148' },
  { name: 'Ridderkerk', id: '0597' },
  { name: 'Almere', id: '0034' },
  { name: 'Opmeer', id: '0432' },
  { name: 'Texel', id: '0448' },
  { name: 'Heerhugowaard', id: '0398' },
  { name: 'Waterland', id: '0852' },
  { name: 'Zwolle', id: '0193' },
  { name: 'Brielle', id: '0501' },
  { name: 'West Maas en Waal', id: '0668' },
  { name: 'Renkum', id: '0274' },
  { name: 'Berg en Dal', id: '1945' },
  { name: 'Heerde', id: '0246' },
  { name: 'Woudenberg', id: '0351' },
  { name: 'Veere', id: '0717' },
  { name: 'Velsen', id: '0453' },
  { name: 'Heiloo', id: '0399' },
  { name: 'Westvoorne', id: '0614' },
  { name: 'Delft', id: '0503' },
  { name: 'Tytsjerksteradiel', id: '0737' },
  { name: 'Utrecht', id: '0344' },
  { name: 'Medemblik', id: '0420' },
  { name: 'Oost Gelre', id: '1586' },
  { name: 'Horst aan de Maas', id: '1507' },
  { name: 'Zwartewaterland', id: '1896' },
  { name: 'Wierden', id: '0189' },
  { name: 'Goeree-Overflakkee', id: '1924' },
  { name: 'Harderwijk', id: '0243' },
  { name: 'Oudewater', id: '0589' },
  { name: 'Hilversum', id: '0402' },
  { name: 'Edam-Volendam', id: '0385' },
  { name: 'Goirle', id: '0785' },
  { name: 'Breda', id: '0758' },
  { name: 'Dinkelland', id: '1774' },
  { name: 'Katwijk', id: '0537' },
  { name: 'Krimpenerwaard', id: '1931' },
  { name: 'Maasdriel', id: '0263' },
  { name: 'Bronckhorst', id: '1876' },
  { name: 'Oldebroek', id: '0269' },
  { name: 'Kerkrade', id: '0928' },
  { name: 'Stadskanaal', id: '0037' },
  { name: 'Blaricum', id: '0376' },
  { name: 'Drechterland', id: '0498' },
  { name: 'Zundert', id: '0879' },
  { name: 'Oss', id: '0828' },
  { name: 'Hoeksche Waard', id: '1963' },
  { name: 'Culemborg', id: '0216' },
  { name: 'Maastricht', id: '0935' },
  { name: 'Bernheze', id: '1721' },
  { name: 'Losser', id: '0168' },
  { name: 'Ommen', id: '0175' },
  { name: 'Smallingerland', id: '0090' },
  { name: 'Brummen', id: '0213' },
  { name: 'Nunspeet', id: '0302' },
  { name: 'Maasgouw', id: '1641' },
  { name: 'Voerendaal', id: '0986' },
  { name: 'Valkenburg aan de Geul', id: '0994' },
  { name: 'Eijsden-Margraten', id: '1903' },
  { name: 'Noordoostpolder', id: '0171' },
  { name: 'Bunschoten', id: '0313' },
  { name: 'Zeist', id: '0355' },
  { name: 'Huizen', id: '0406' },
  { name: 'Heemstede', id: '0397' },
  { name: 'Dongen', id: '0766' },
  { name: 'Sliedrecht', id: '0610' },
  { name: 'Borsele', id: '0654' },
  { name: 'Haarlemmermeer', id: '0394' },
  { name: 'Twenterand', id: '1700' },
  { name: 'Kaag en Braassem', id: '1884' },
  { name: 'Dantumadiel', id: '1891' },
  { name: 'Epe', id: '0232' },
  { name: 'Lingewaard', id: '1705' },
  { name: 'Hulst', id: '0677' },
  { name: 'Landerd', id: '1685' },
  { name: 'Geldrop-Mierlo', id: '1771' },
  { name: 'Hardinxveld-Giessendam', id: '0523' },
  { name: 'Vlaardingen', id: '0622' },
  { name: 'Heerenveen', id: '0074' },
  { name: 'Woerden', id: '0632' },
  { name: 'Laarbeek', id: '1659' },
  { name: 'Drimmelen', id: '1719' },
  { name: 'Hellendoorn', id: '0163' },
  { name: 'Duiven', id: '0226' },
  { name: 'Heerlen', id: '0917' },
  { name: 'Renswoude', id: '0339' },
  { name: 'Loon op Zand', id: '0809' },
  { name: 'Hillegom', id: '0534' },
  { name: 'Sint-Michielsgestel', id: '0845' },
  { name: 'Terschelling', id: '0093' },
  { name: 'West Betuwe', id: '1960' },
  { name: 'Wijchen', id: '0296' },
  { name: 'Roerdalen', id: '1669' },
  { name: 'Venray', id: '0984' },
  { name: 'Uden', id: '0856' },
  { name: 'Krimpen aan den IJssel', id: '0542' },
  { name: 'Barendrecht', id: '0489' },
  { name: 'Zoeterwoude', id: '0638' },
  { name: 'Pijnacker-Nootdorp', id: '1926' },
  { name: 'Noordenveld', id: '1699' },
  { name: 'Uithoorn', id: '0451' },
  { name: 'Eersel', id: '0770' },
  { name: 'Zuidplas', id: '1892' },
  { name: 'Sittard-Geleen', id: '1883' },
  { name: 'Zeewolde', id: '0050' },
  { name: 'Schagen', id: '0441' },
  { name: 'Cuijk', id: '1684' },
  { name: 'Bergeijk', id: '1724' },
  { name: 'Olst-Wijhe', id: '1773' },
  { name: 'Maassluis', id: '0556' },
  { name: 'Rozendaal', id: '0277' },
  { name: 'Mook en Middelaar', id: '0944' },
  { name: 'Brunssum', id: '0899' },
  { name: 'Coevorden', id: '0109' },
  { name: 'Rhenen', id: '0340' },
  { name: 'Soest', id: '0342' },
  { name: 'Amsterdam', id: '0363' },
  { name: 'Wormerland', id: '0880' },
  { name: 'Uitgeest', id: '0450' },
  { name: 'Reusel-De Mierden', id: '1667' },
  { name: 'Oosterhout', id: '0826' },
  { name: 'Nissewaard', id: '1930' },
  { name: 'De Fryske Marren', id: '1940' },
  { name: 'Voorst', id: '0285' },
  { name: 'Zutphen', id: '0301' },
  { name: 'Urk', id: '0184' },
  { name: 'Vijfheerenlanden', id: '1961' },
  { name: 'Lopik', id: '0331' },
  { name: 'Terneuzen', id: '0715' },
  { name: 'Purmerend', id: '0439' },
  { name: 'Best', id: '0753' },
  { name: 'Rijswijk', id: '0603' },
  { name: 'Vlissingen', id: '0718' },
  { name: 'Aalsmeer', id: '0358' },
  { name: 'Boxmeer', id: '0756' },
  { name: 'Tubbergen', id: '0183' },
  { name: 'Raalte', id: '0177' },
  { name: 'Rheden', id: '0275' },
  { name: 'Zevenaar', id: '0299' },
  { name: 'Nederweert', id: '0946' },
  { name: 'Stede Broec', id: '0532' },
  { name: 'Hengelo (O)', id: '0164' },
  { name: 'Gouda', id: '0513' },
  { name: 'Beuningen', id: '0209' },
  { name: 'Landgraaf', id: '0882' },
  { name: 'Emmen', id: '0114' },
  { name: 'Staphorst', id: '0180' },
  { name: 'Montferland', id: '1955' },
  { name: 'Gulpen-Wittem', id: '1729' },
  { name: 'Beverwijk', id: '0375' },
  { name: 'Eindhoven', id: '0772' },
  { name: 'Bergen op Zoom', id: '0748' },
  { name: 'Wassenaar', id: '0629' },
  { name: 'Borger-Odoorn', id: '1681' },
  { name: 'Zaanstad', id: '0479' },
  { name: 'Roermond', id: '0957' },
  { name: 'Oisterwijk', id: '0824' },
  { name: 'Vught', id: '0865' },
  { name: 'Tilburg', id: '0855' },
  { name: 'Oude IJsselstreek', id: '1509' },
  { name: 'Scherpenzeel', id: '0279' },
  { name: 'Aa en Hunze', id: '1680' },
  { name: 'Asten', id: '0743' },
  { name: 'Nieuwkoop', id: '0569' },
  { name: 'Lansingerland', id: '1621' },
  { name: 'Alphen aan den Rijn', id: '0484' },
  { name: 'Achtkarspelen', id: '0059' },
  { name: 'Apeldoorn', id: '0200' },
  { name: 'Groningen', id: '0014' },
  { name: 'De Bilt', id: '0310' },
  { name: 'Sluis', id: '1714' },
  { name: 'Leiden', id: '0546' },
  { name: 'Leiderdorp', id: '0547' },
  { name: 'Bodegraven-Reeuwijk', id: '1901' },
  { name: 'Hendrik-Ido-Ambacht', id: '0531' },
  { name: 'Hattem', id: '0244' },
  { name: 'Meerssen', id: '0938' },
  { name: 'Pekela', id: '0765' },
  { name: 'Tynaarlo', id: '1730' },
  { name: 'Meppel', id: '0119' },
  { name: 'Stichtse Vecht', id: '1904' },
  { name: 'Wijk bij Duurstede', id: '0352' },
  { name: 'Weesp', id: '0457' },
  { name: 'Waalwijk', id: '0867' },
  { name: 'Nuenen,Gerwen en Nederwetten', id: '0820' },
  { name: 'Deventer', id: '0150' },
  { name: 'Bergen (L)', id: '0893' },
  { name: 'Amersfoort', id: '0307' },
  { name: 'Baarn', id: '0308' },
  { name: 'Waddinxveen', id: '0627' },
  { name: 'IJsselstein', id: '0353' },
  { name: 'Laren', id: '0417' },
  { name: 'Beemster', id: '0370' },
  { name: 'Landsmeer', id: '0415' },
  { name: 'Oirschot', id: '0823' },
  { name: 'Baarle-Nassau', id: '0744' },
  { name: 'Kampen', id: '0166' },
  { name: 'Weststellingwerf', id: '0098' },
  { name: 'Hoorn', id: '0405' },
  { name: 'Cranendonck', id: '1706' },
  { name: 'Enschede', id: '0153' },
  { name: 'Hardenberg', id: '0160' },
  { name: 'Albrandswaard', id: '0613' },
  { name: 'Opsterland', id: '0086' },
  { name: 'Vlieland', id: '0096' },
  { name: 'De Wolden', id: '1690' },
  { name: 'Langedijk', id: '0416' },
  { name: 'Gooise Meren', id: '1942' },
  { name: 'Deurne', id: '0762' },
  { name: 'Hof van Twente', id: '1735' },
  { name: 'Ameland', id: '0060' },
  { name: 'Barneveld', id: '0203' },
  { name: 'Ermelo', id: '0233' },
  { name: 'Tiel', id: '0281' },
  { name: 'Neder-Betuwe', id: '1740' },
  { name: 'Beekdaelen', id: '1954' },
  { name: 'Venlo', id: '0983' },
  { name: 'Westerwolde', id: '1950' },
  { name: 'Veenendaal', id: '0345' },
  { name: 'Bloemendaal', id: '0377' },
  { name: 'Amstelveen', id: '0362' },
  { name: 'Haarlem', id: '0392' },
  { name: 'Geertruidenberg', id: '0779' },
  { name: 'Schiedam', id: '0606' },
  { name: 'Beesel', id: '0889' },
  { name: 'Hoogeveen', id: '0118' },
  { name: 'Bergen (NH)', id: '0373' },
  { name: 'Ouder-Amstel', id: '0437' },
  { name: 'Helmond', id: '0794' },
  { name: 'Hellevoetsluis', id: '0530' },
  { name: 'Lochem', id: '0262' },
  { name: 'Oldambt', id: '1895' },
  { name: 'Castricum', id: '0383' },
  { name: 'Someren', id: '0847' },
  { name: 'Son en Breugel', id: '0848' },
  { name: 'Borne', id: '0147' },
  { name: 'Voorschoten', id: '0626' },
  { name: 'Stein', id: '0971' },
  { name: 'Koggenland', id: '1598' },
  { name: 'Halderberge', id: '1655' },
  { name: 'Waalre', id: '0866' },
  { name: 'Putten', id: '0273' },
  { name: 'Ede', id: '0228' },
  { name: 'Arnhem', id: '0202' },
  { name: 'Westerkwartier', id: '1969' },
  { name: 'Midden-Groningen', id: '1952' },
  { name: 'Den Helder', id: '0400' },
  { name: 'Zandvoort', id: '0473' },
  { name: 'Oldenzaal', id: '0173' },
  { name: 'Capelle aan den IJssel', id: '0502' },
  { name: 'Alblasserdam', id: '0482' },
  { name: 'Westervoort', id: '0293' },
  { name: 'Veldhoven', id: '0861' },
  { name: 'Etten-Leur', id: '0777' },
  { name: 'Schiermonnikoog', id: '0088' },
  { name: 'Echt-Susteren', id: '1711' },
  { name: 'Dronten', id: '0303' },
  { name: 'Oegstgeest', id: '0579' },
  { name: 'Dordrecht', id: '0505' },
  { name: 'Hollands Kroon', id: '1911' },
  { name: 'Enkhuizen', id: '0388' },
  { name: 'Moerdijk', id: '1709' },
  { name: 'Heusden', id: '0797' },
  { name: 'Aalten', id: '0197' },
  { name: 'Weert', id: '0988' },
  { name: 'Doesburg', id: '0221' },
  { name: 'Beek', id: '0888' },
  { name: 'Westerveld', id: '1701' },
  { name: 'Rucphen', id: '0840' },
  { name: 'Valkenswaard', id: '0858' },
  { name: 'Gilze en Rijen', id: '0784' },
  { name: 'Druten', id: '0225' },
  { name: 'Elburg', id: '0230' },
  { name: 'Schouwen-Duiveland', id: '1676' },
  { name: 'Tholen', id: '0716' },
  { name: 'Almelo', id: '0141' },
  { name: 'Zoetermeer', id: '0637' },
  { name: 'Zwijndrecht', id: '0642' },
  { name: 'Alkmaar', id: '0361' },
  { name: 'Boxtel', id: '0757' },
  { name: '\'s-Gravenhage', id: '0518' },
  { name: 'Waadhoeke', id: '1949' },
  { name: 'Buren', id: '0214' },
  { name: 'Nieuwegein', id: '0356' },
  { name: 'Reimerswaal', id: '0703' },
  { name: 'Diemen', id: '0384' },
  { name: 'Grave', id: '0786' },
  { name: 'Mill en Sint Hubert', id: '0815' },
  { name: 'Bladel', id: '1728' },
  { name: 'Gorinchem', id: '0512' },
  { name: 'Súdwest-Fryslân', id: '1900' },
  { name: 'Midden-Drenthe', id: '1731' },
  { name: 'Meierijstad', id: '1948' },
  { name: 'Ooststellingwerf', id: '0085' },
  { name: 'Doetinchem', id: '0222' },
  { name: 'Middelburg', id: '0687' },
  { name: 'Heeze-Leende', id: '1658' },
  { name: 'Noordwijk', id: '0575' },
  { name: 'Heumen', id: '0252' },
  { name: 'Assen', id: '0106' },
  { name: 'Eemnes', id: '0317' },
  { name: 'Bunnik', id: '0312' },
  { name: 'Steenwijkerland', id: '1708' },
  { name: 'Leeuwarden', id: '0080' },
  { name: 'Gennep', id: '0907' },
  { name: 'Leudal', id: '1640' },
  { name: 'Het Hogeland', id: '1966' },
  { name: 'Hilvarenbeek', id: '0798' },
  { name: 'Lisse', id: '0553' },
  { name: 'Peel en Maas', id: '1894' },
]
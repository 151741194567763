/* eslint-disable max-len */
export const Xxllnc = ({ className, fill }: SvgType): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" fill={fill} className={className} viewBox="0 0 175.3973389 42.227169">
    <path d="M78.2620544,40.7328224h7.1602783V0.3559315h-7.1602783V40.7328224z M94.7875061,40.7328224h7.1602783
	V0.3559315h-7.1602783V40.7328224z M64.4803162,9.4169054L53.4260559,20.8089352L42.3717957,9.4169054l-4.7658081,4.911438
	l11.0542603,11.3919687L37.6059875,37.112339l4.7658081,4.911438l11.0542603-11.3920269L64.4803162,42.023777l4.7658081-4.911438
	L58.191864,25.7203121l11.0542603-11.3919687L64.4803162,9.4169054z M27.1794128,9.4169054L16.1251526,20.8089352
	L5.0708923,9.4169054l-4.7658081,4.911438l11.0542603,11.3919687L0.3050841,37.112339l4.7658081,4.911438l11.0542603-11.3920269
	L27.1794128,42.023777l4.7658081-4.911438L20.8909607,25.7203121l11.0542603-11.3919687L27.1794128,9.4169054z
	 M124.8292542,10.6589708c-8.2484741,0-14.2631836,5.1537476-14.2631836,14.1484385v15.9254131h7.1602783V24.9796505
	c0-4.7544556,3.0348511-7.3325806,7.1029053-7.3325806c4.0681152,0,7.1029663,2.578125,7.1029663,7.3325806v15.7531719h7.1602783
	V24.8074093C139.0924988,15.8127184,133.0203552,10.6589708,124.8292542,10.6589708z M160.3836365,34.7610817
	c-4.5479736,0-8.2351074-3.9302349-8.2351074-8.7791119c0-4.8459473,3.6871338-8.7761841,8.2351074-8.7761841
	c3.3699341,0,6.2583618,2.1628418,7.5334473,5.2529297h7.1751709c-1.2601929-6.9884043-7.1054077-11.7994394-14.6101074-11.7994394
	c-8.7623901,0-15.1791992,6.4709482-15.1791992,15.3517466c0,8.822691,6.4168091,15.2965679,15.1791992,15.2965679
	c7.5046997,0,13.2924805-4.758728,14.6101074-11.7471294h-7.203125
	C166.6020203,32.6214943,163.7328796,34.7610817,160.3836365,34.7610817z"/>
  </svg>
)


export const Dot = ({ className, fill }: SvgType): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} height="5px" width="5px" viewBox="0 0 1 1">
    <circle fill={fill} cx="0.5" cy="0.5" r="0.5" />
  </svg>
)

export const moreAppsBase64 = 'PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHZpZXdCb3g9IjAgMCA1MCA1MCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMF8yXzM1KSI+DQo8cGF0aCBkPSJNMjIuNSAwSDBWMjIuNUgyMi41VjBaIiBmaWxsPSIjMzE2NzlCIi8+DQo8L2c+DQo8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDFfMl8zNSkiPg0KPHBhdGggZD0iTTIyLjUgMjcuNUgwVjUwSDIyLjVWMjcuNVoiIGZpbGw9IiNFNTk5MjUiLz4NCjwvZz4NCjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwMl8yXzM1KSI+DQo8cGF0aCBkPSJNNTAgMEgyNy41VjIyLjVINTBWMFoiIGZpbGw9IiMwMDZFNUIiLz4NCjwvZz4NCjxnIGNsaXAtcGF0aD0idXJsKCNjbGlwM18yXzM1KSI+DQo8cGF0aCBkPSJNNTAgMjcuNUgyNy41VjUwSDUwVjI3LjVaIiBmaWxsPSIjRTE1QjMyIi8+DQo8L2c+DQo8ZGVmcz4NCjxjbGlwUGF0aCBpZD0iY2xpcDBfMl8zNSI+DQo8cmVjdCB3aWR0aD0iMjIuNSIgaGVpZ2h0PSIyMi41IiByeD0iOCIgZmlsbD0id2hpdGUiLz4NCjwvY2xpcFBhdGg+DQo8Y2xpcFBhdGggaWQ9ImNsaXAxXzJfMzUiPg0KPHJlY3QgeT0iMjcuNSIgd2lkdGg9IjIyLjUiIGhlaWdodD0iMjIuNSIgcng9IjgiIGZpbGw9IndoaXRlIi8+DQo8L2NsaXBQYXRoPg0KPGNsaXBQYXRoIGlkPSJjbGlwMl8yXzM1Ij4NCjxyZWN0IHg9IjI3LjUiIHdpZHRoPSIyMi41IiBoZWlnaHQ9IjIyLjUiIHJ4PSI4IiBmaWxsPSJ3aGl0ZSIvPg0KPC9jbGlwUGF0aD4NCjxjbGlwUGF0aCBpZD0iY2xpcDNfMl8zNSI+DQo8cmVjdCB4PSIyNy41IiB5PSIyNy41IiB3aWR0aD0iMjIuNSIgaGVpZ2h0PSIyMi41IiByeD0iOCIgZmlsbD0id2hpdGUiLz4NCjwvY2xpcFBhdGg+DQo8L2RlZnM+DQo8L3N2Zz4NCg=='

interface SvgType {
  className?: string;
  fill?: string;
}

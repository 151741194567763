import { createTheme } from '@mui/material/styles'
import { color } from './color'
import { typography } from './typography'

const material = createTheme()

export const RaGlobalRoot = {
  [material.breakpoints.up('md')]: {
    maxWidth: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
  ['& .MuiToolbar-root']: {
    alignItems: 'center',
    display: 'flex',
    fontFamily: typography.headline,
    minHeight: 'auto',
    backgroundColor: color.white,
    borderRadius: 0,
  },
}

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { FC, useState } from 'react'
import {
  AutocompleteInput,
  FileField,
  FileInput,
  required,
  SimpleForm,
  Toolbar,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate
} from 'react-admin'
import { municipalities } from '../../../utils/municipalityCodes'

interface RAFile {
    rawFile: File
    src?: string
    title?: string
}

interface BulkImportDialogProps {
  open: boolean
  onClose: () => void
}

const BulkImportDialog: FC<BulkImportDialogProps> = ({ open, onClose }) => {
  const tranlate = useTranslate()
  const [gemeentecode, setGemeentecode] = useState<number>()
  const [files, setFiles] = useState<RAFile[]>([])
  const notify = useNotify()
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const handleSubmit = async () => {
    if (!gemeentecode || files.length === 0) {
      notify('resources.objections.bulkImport.warning', { type: 'warning' })
      return
    }

    for (const file of files) {
      try {
        await dataProvider.create('objection',  { data: { file, gemeentecode }})
        notify('resources.objections.bulkImport.completed', { type: 'info' })
      } catch (error) {
        notify('resources.objections.bulkImport.error', { type: 'error' })
      }
    }

    onClose()
    refresh()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{ tranlate('resources.objections.bulkImport.title') }</DialogTitle>
      <DialogContent>
        <SimpleForm toolbar={<Toolbar style={{ display: 'none' }} />} onSubmit={handleSubmit}>
          <AutocompleteInput
            source="gemeentecode"
            label="data.gemeente"
            choices={municipalities}
            fullWidth
            validate={required()}
            onChange={(value: number) => setGemeentecode(value)}
          />
          <FileInput
            source="files"
            label="data.files"
            accept={{ 'application/pdf': ['.pdf'] }}
            multiple
            onChange={(data: File[]) => {
              setFiles(data.map(file => ({
                rawFile: file,
                title: file.name,
                src: URL.createObjectURL(file)
              })))
            }}
          >
            <FileField source="src" title="title" />
          </FileInput>
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          { tranlate('resources.objections.bulkImport.cancel') }
        </Button>
        <Button onClick={() => void handleSubmit()} color='primary'>
          { tranlate('resources.objections.bulkImport.upload') }
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BulkImportDialog

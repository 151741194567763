/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** Body_create_objection_api_v1_objection_post */
export interface BodyCreateObjectionApiV1ObjectionPost {
  /** Gemeentecode */
  gemeentecode: string;
  /**
   * File
   * @format binary
   */
  file: File;
}

/** ConfigurationOut */
export interface ConfigurationOut {
  /**
   * Unique identifier
   * @format uuid
   */
  id: string;
  /** Welcome message */
  welcomeMessage?: string | null;
}

/** ConfigurationUpdate */
export interface ConfigurationUpdate {
  /** Welcome message */
  welcomeMessage?: string | null;
}

/** Grief */
export interface Grief {
  /** Griefid */
  griefid: number;
  /** Citaat */
  citaat: string;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** Inboekvariabelen */
export interface Inboekvariabelen {
  /** Dagtekening */
  dagtekening: string;
  /** Proforma */
  ProForma: boolean;
  /** Ontvankelijk */
  Ontvankelijk: boolean;
  /** Horen */
  Horen: boolean;
  /** Taxatieverslag */
  Taxatieverslag: boolean;
  /** Onkostenvergoeding */
  Onkostenvergoeding: boolean;
  /** Uitstelbetaling */
  UitstelBetaling: boolean;
}

/** ObjectionOut */
export interface ObjectionOut {
  /**
   * Status
   * The status of the objection
   */
  status: ObjectionStatus;
  /** Objection description */
  description: string;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the objection
   * @format uuid
   */
  id: string;
  /**
   * Organization UUID
   * @format uuid
   */
  organization_uuid: string;
  /** Municipality code */
  gemeentecode: string;
  /** Unique identifier of the objection */
  bezwaarid: number;
  /** Inbook variables */
  inboekvariabelen: Inboekvariabelen;
  /** List of grievances */
  grieven: Grief[];
}

/** ObjectionStatus */
export enum ObjectionStatus {
  RECEIVED = "RECEIVED",
  UNDER_REVIEW = "UNDER_REVIEW",
  ADDITIONAL_INFORMATION_REQUESTED = "ADDITIONAL_INFORMATION_REQUESTED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  REVISION_REQUESTED = "REVISION_REQUESTED",
  RESOLVED = "RESOLVED",
  CANCELLED = "CANCELLED",
}

/** ObjectionUpdate */
export interface ObjectionUpdate {
  /**
   * Status
   * The status of the objection
   */
  status: ObjectionStatus;
  /** Objection description */
  description: string;
  /** Municipality code */
  gemeentecode: string;
  /** Unique identifier of the objection */
  bezwaarid: number;
  /** Inbook variables */
  inboekvariabelen: Inboekvariabelen;
  /** List of grievances */
  grieven: Grief[];
}

/** RevisionnOut */
export interface RevisionnOut {
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the revision
   * @format uuid
   */
  id: string;
  /** Objection id */
  objection_id: number;
  /** List of original grievances */
  original_grieven: Grief[];
  /** List of revised grievances */
  revised_grieven: Grief[];
}

/** StatsOut */
export interface StatsOut {
  /** Objections in progress */
  activeObjections: number;
  /** New objections */
  newObjections: number;
  /** Revisions in the last month */
  lastMonthRevisions: number;
  /** Unique identifier of the requestor */
  id: string;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

import { styled, Theme } from '@mui/material/styles'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { usePermissions } from 'react-admin'
import { IPermissions } from '../../types/apiTypes'
import { ContainerAlign } from '../buttons/ButtonTypes'

export const RestrictedContainer = ({
  access,
  align = 'left',
  children,
  className,
  fullWidth,
  ...props
}: RestrictedContainerProps): JSX.Element | null => {
  const styleProps = {
    fullWidth,
  }
  const { permissions } = usePermissions() as IPermissions

  if (permissions !== undefined && access.some((i: string) => permissions?.scopes.includes(i))) {
    return (
      <Root className={clsx(classes.root, classes[`${align}`], className)} {...props} {...styleProps}>
        {children}
      </Root>
    )
  }

  return null
}

interface RestrictedContainerProps {
  access: string[];
  align?: ContainerAlign;
  children: JSX.Element;
  className?: string;
  fullWidth?: boolean;
}

RestrictedContainer.propTypes = {
  access: PropTypes.arrayOf(PropTypes.string).isRequired,
  align: PropTypes.oneOf<ContainerAlign>([
    'left',
    'center',
    'right'
  ]),
  cellClassName: PropTypes.string,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  headerClassName: PropTypes.string,
}

interface StyleProps {
  fullWidth?: string;
}

const PREFIX = 'xxllncRestrictedContainer'

const classes = {
  root: `${PREFIX}-root`,
  left: `${PREFIX}-left`,
  center: `${PREFIX}-center`,
  right: `${PREFIX}-right`,
}

const Root = styled('div', {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})(({ theme, fullWidth }: StyleProps & { theme?: Theme }) => ({
  [`&.${classes.root}`]: {
    width: fullWidth ? '100%' : 'initial',
  },
  [`&.${classes.left}`]: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  [`&.${classes.center}`]: {
    display: 'flex',
    justifyContent: 'center',
  },
  [`&.${classes.right}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import DateRangeIcon from '@mui/icons-material/DateRange'
import HourglassEmptySharpIcon from '@mui/icons-material/HourglassEmptySharp'
import { Box, Grid, styled, Typography } from '@mui/material'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import { ObjectionOut, ObjectionStatus } from '../../types/apiRequests'
import { dateFormatter } from '../../utils/dateFormatter'

interface ObjectionStatusProps {
  record?: ObjectionOut
}

const ObjectionStatusComponent: FC<ObjectionStatusProps> = ({ record }) => {
  const translate = useTranslate()

  if (!record) return null

  const isClosed = record?.status === ObjectionStatus.ACCEPTED ||
    record?.status === ObjectionStatus.REJECTED ||
    record?.status === ObjectionStatus.RESOLVED ||
    record?.status === ObjectionStatus.CANCELLED

  const classForStatus = () => {
    switch (record.status) {
      case ObjectionStatus.RECEIVED:
      case ObjectionStatus.ADDITIONAL_INFORMATION_REQUESTED:
      case ObjectionStatus.REVISION_REQUESTED:
        return classes.orange
      case ObjectionStatus.REJECTED:
      case ObjectionStatus.CANCELLED:
        return classes.red
      case ObjectionStatus.UNDER_REVIEW:
      case ObjectionStatus.RESOLVED:
      case ObjectionStatus.ACCEPTED:
        return classes.green
      default:
        return classes.gray
    }
  }

  return (
    <StyledGrid container spacing={2} className={classes.root}>
      <Grid item sm={12} md={6}>
        <Box className={classForStatus()}>
          { isClosed ? <CheckCircleOutlineIcon/> : <HourglassEmptySharpIcon />}
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
            <Typography variant="body1" sx={{ color: isClosed ? 'rgba(71,71,70,1)' : '#28282C', fontSize: '10pt', fontWeight: 600 }}>
              { translate('data.status') }
            </Typography>
            <Typography variant="body1" sx={{ color: isClosed ? 'rgba(71,71,70,1)' : '#6E6E74', fontSize: '10pt' }}>
              { translate(`resources.objections.status.${record.status}`) }
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item sm={12} md={6}>
        <Box className={classes.gray}>
          <DateRangeIcon />
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
            <Typography variant="body1" sx={{ fontSize: '10pt', fontWeight: 600 }}>
              { translate(isClosed ? 'data.closingDate' : 'data.lastUpdated') }
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '8pt' }}>
              { dateFormatter(record.lastModified) }
            </Typography>
          </Box>
        </Box>
      </Grid>
    </StyledGrid>
  )
}

const PREFIX = 'xxllncObjectionStatus'

const classes = {
  root: `${PREFIX}-root`,
  green: `${PREFIX}-green`,
  red: `${PREFIX}-red`,
  orange: `${PREFIX}-orange`,
  gray: `${PREFIX}-gray`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: { },
  [`& .${classes.green}`]: {
    background: '#D1F0D6',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    height: '60px',
    ['& .MuiSvgIcon-root']: {
      color: '#006E5B',
    },
  },
  [`& .${classes.red}`]: {
    background: '#FFD6D6',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    height: '60px',
    ['& .MuiSvgIcon-root']: {
      color: '#D8000C',
    },
  },
  [`& .${classes.orange}`]: {
    background: '#FFEDD6',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    height: '60px',
    ['& .MuiSvgIcon-root']: {
      color: '#FF8C00',
    },
  },
  [`& .${classes.gray}`]: {
    background: '#F2F2F2',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px',
    height: '60px',
  },
  '& .MuiSvgIcon-root': {
    alignSelf: 'center',
  },
}))

export default ObjectionStatusComponent
export const dateFormatter = (dateString: string | undefined, withTime = true): string => {
  if(!dateString) return '-'

  let date: Date
  try {
    date = new Date(dateString)
  } catch (error) {
    return '-'
  }
  const locale = window.navigator.language || 'nl-NL'

  const formattedDate = date.toLocaleDateString(locale, {
    month: 'long',
    day: '2-digit',
    year: 'numeric'
  })

  const formattedTime = date.toLocaleTimeString(locale, {
    hour: '2-digit',
    minute:'2-digit'
  })

  return `${formattedDate}${withTime ? ` - ${formattedTime}` : ''}`
}
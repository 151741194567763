/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable camelcase */
import dutchMessages from 'ra-language-dutch'
import { StringMap } from 'react-admin'

export const i18nDutch: StringMap = {
  ...dutchMessages,
  dashboard: {
    greeting: {
      morning: 'Goedemorgen',
      afternoon: 'Goedemiddag',
      evening: 'Goedenavond',
    },
    description: 'Welkom bij xxllnc bezwaren.',
    descriptionAdmin: 'Welkom bij xxllnc bezwaren.',
  },
  resources: {
    objections: {
      list: 'Ingediende bezwaren',
      edit: 'Bezwaar behandelen',
      editShort: 'Behandelen',
      show: 'Bezwaar inzien',
      create: 'Bezwaar indienen',
      bulkImport: {
        title: 'Bulk import',
        warning: 'Selecteer een gemeente en ten minste één bezwaar.',
        upload: 'Importeren',
        cancel: 'Annuleren',
        completed: 'Bezwaren succesvol geüpload.',
        error: 'Er is een fout opgetreden bij het uploaden van de bezwaren.',
      },
      status: {
        RECEIVED: 'Ontvangen',
        UNDER_REVIEW: 'In behandeling',
        ADDITIONAL_INFORMATION_REQUESTED: 'Aanvullende informatie opgevraagd',
        ACCEPTED: 'Geaccepteerd',
        REJECTED: 'Afgewezen',
        REVISION_REQUESTED: 'Herziening aangevraagd',
        RESOLVED: 'Afgehandeld',
        CANCELLED: 'Geannuleerd',
      },
      properties: {
        ProForma: 'Proforma',
        Ontvankelijk: 'Ontvankelijk',
        Horen: 'Horen',
        Taxatieverslag: 'Taxatieverslag',
        Onkostenvergoeding: 'Onkostenvergoeding',
        UitstelBetaling: 'Uitstel betaling',
      },
    },
  },
  data: {
    objections: 'Bezwaren',
    revisions: 'Revisies',
    inProgress: 'In behandeling',
    new: 'Nieuw',
    lastMonth: 'Laatste maand',
    griefid: 'Grief ID',
    description: 'Omschrijving',
    completed: 'Afgerond',
    list: 'Bezwaren',
    gemeente: 'Gemeente',
    bezwaarid: 'Bezwaar ID',
    dagtekening: 'Dagtekening',
    status: 'Status',
    created: 'Aanmaak datum',
    lastUpdated: 'Laatst bijgewerkt',
    closingDate: 'Sluitingsdatum',
    proforma: 'Proforma',
    properties: 'Eigenschappen',
    grieven: 'Grieven',
    citaat: 'Citaat',
    notReadyYet: 'Het bezwaar wordt op dit moment nog verwerkt. Een moment gedult alstublieft...',
  },
  login: {
    title: 'Inloggen',
    button: 'Inloggen',
    choose: 'Kies voor welke organisatie je wilt inloggen:',
    email: 'Email',
    emailHint: 'Vul je emailadres in om door te gaan.',
    fetchUserInfo: 'Een moment geduld, we halen je gebruikersinformatie op.',
    hint: 'Log in op mijn.xxllnc.nl',
    incognitoError: 'Authenticatie niet mogelijk in incognito mode',
    loginCompleted: 'Succesvol ingelogd!',
    unknownOrganizationId: 'Het opgegeven organisatie-id is onbekend',
    notValid: 'Het opgegeven emailadres is niet geldig',
    byXxllnc: 'Bezwaren is een applicatie van xxllnc',
  },
  page: {
    unauthorized: {
      headerTitle: 'Onbevoegd',
      defaultTitle: 'Onbevoegd',
      title: 'Voor deze actie ben je niet bevoegd.',
      text: 'Je hebt geprobeerd een actie uit te voeren waar je geen rechten voor hebt.',
    },
  }
}

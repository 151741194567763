import { AuthProvider } from 'ra-core'
import { getIsAuthenticated, getUser, logout } from '../auth0'
import { debug, mock } from '../test'
import authToken from '../utils/authToken'
import { getScope } from '../utils/getScope'

const clearLocalStorage = (): void => {
  authToken.deleteToken()
  authToken.deleteOrganization()
  localStorage.removeItem('nickname')
}

const authProvider = (): AuthProvider => ({
  login: (): Promise<any> => Promise.resolve(),
  logout: async (): Promise<any> => {
    const isAuthenticated = await getIsAuthenticated()
    if (isAuthenticated) await logout()
    clearLocalStorage()
    return Promise.resolve()
  },
  checkError: async (error: { status: number }): Promise<any> => {
    if (error?.status === 412) {
      debug && console.log('Busy with login, please try again later')
      return Promise.reject()
    }
    if (error?.status === 401) {
      debug && console.log('You are not authenticated, logging you out')
      const isAuthenticated = await getIsAuthenticated()
      if (isAuthenticated) void logout()
      clearLocalStorage()
      return Promise.reject()
    }
    if (error?.status === 403) {
      debug && console.log('You are unauthorized')
      return Promise.reject({ redirectTo: '/unauthorized', logoutUser: false })
    }
    return Promise.resolve()
  },
  checkAuth: async (): Promise<any> => {
    if (mock() || authToken.getToken()) return Promise.resolve()
    const isAuthenticated = await getIsAuthenticated()
    if (!isAuthenticated) return Promise.reject({ message: false })
    void logout()
    return Promise.reject({ message: false })
  },
  getPermissions: (): Promise<any> => Promise.resolve({ scopes: getScope() as [] }),
  getIdentity: async (): Promise<any> => {
    if (mock()) return Promise.resolve({
      id: 1,
      fullName: 'Tessa tester',
      organizationId: 'bf585e6d-e641-4ef7-be55-9d3a7054773a'
    })
    const user = await getUser()
    if (!user) {
      return Promise.resolve({
        id: 1,
        fullName: localStorage.getItem('nickname') || ''
      })
    }

    return Promise.resolve({
      id: user.sub || '',
      // TODO: get name or nickname from user
      fullName: '',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      organizationId: user.org_id,
    })
  },
})

export default authProvider

import DownloadIcon from '@mui/icons-material/Download'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { FC } from 'react'
import { Button } from 'react-admin'

type ConvertibleToString = string | number | boolean

type DataObject = Record<string, ConvertibleToString>

const generatePDF = <T extends DataObject>(data: T[], fileName: string): void => {
  const doc = new jsPDF()

  const headers = data.length > 0 ? [Object.keys(data[0])] : [[]]

  const body = data.map(obj => Object.values(obj).map(value => {
    if (typeof value === 'boolean') {
      return value ? 'Ja' : 'Nee'
    }
    return value.toString()
  }))

  // @ts-expect-error - autoTable is not typed correctly in jspdf
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  doc.autoTable({
    head: headers,
    body,
  })

  doc.save(`${fileName}.pdf`)
}

interface DownloadPdfButtonProps<T> {
    fileName: string
    data: T[] | undefined
}

const DownloadPdfButton: FC<DownloadPdfButtonProps<DataObject>> = ({ fileName, data }) => (
  <Button
    variant="text"
    sx={{ maxWidth: '200px'}}
    disabled={!data || data.length === 0}
    onClick={() => {
      if (data) generatePDF(data, fileName)
    }} label="download"
  >
    <DownloadIcon />
  </Button>
)

export default DownloadPdfButton

import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { DeleteButton, RedirectionSideEffect, SaveButton, Toolbar, useRecordContext, useTranslate } from 'react-admin'
import { RestrictedButton } from '..'

export const ConfirmDeleteToolbar = ({
  access,
  redirect,
  saveLabel,
  ...props
}: ConfirmDeleteToolbarProps): JSX.Element => {
  const translate = useTranslate()
  const record = useRecordContext()

  return (
    <StyledToolbar
      className={classes.root}
      {...props}
    >
      <RestrictedButton
        access={access}
        align="right"
      >
        {(): JSX.Element =>
          <DeleteButton
            confirmTitle={translate('message.deleteTitle', record?.name)}
            icon={<></>}
            mutationMode="pessimistic"
            record={record}
            redirect={redirect}
            size="medium"
            {...props}
          />
        }
      </RestrictedButton>
      <SaveButton label={saveLabel || 'ra.action.save'} icon={<></>} />
    </StyledToolbar>
  )
}

const PREFIX = 'xxllncConfirmDeleteToolbar'

const classes = {
  root: `${PREFIX}-root`,
}

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [`&.${classes.root}`]: {
    justifyContent: 'flex-end'
  },
}))

interface ConfirmDeleteToolbarProps {
  access: string[];
  redirect?: RedirectionSideEffect;
  saveLabel?: string;
}

ConfirmDeleteToolbar.propTypes = {
  access: PropTypes.arrayOf(PropTypes.string).isRequired,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
}

import { FC } from 'react'
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from 'react-admin'
import { ActionsToolbar, HeaderTitle } from '../../components'
import { ObjectionStatus } from '../../types/apiRequests'
import { municipalities } from '../../utils/municipalityCodes'
import { required } from '../../utils/validation'

const ObjectionsEdit: FC = () => {
  const choices = Object.values(ObjectionStatus).map(status => ({
    id: status,
    name: `resources.objections.status.${status}`,
  }))

  return (
    <Edit
      actions={<ActionsToolbar i18n="resources.objections.edit" />}
      title={<HeaderTitle i18n="resources.objections.edit" />}
      redirect="show"
    >
      <SimpleForm>
        <NumberInput label="data.bezwaarid" source="bezwaarid" />
        <TextInput
          source="description"
          label="data.description"
          validate={required()}
        />
        <AutocompleteInput
          source="gemeentecode"
          label="data.gemeente"
          choices={municipalities}
          fullWidth
          validate={required()}
        />
        <DateInput source="inboekvariabelen.dagtekening" label="data.dagtekening" />
        <BooleanInput
          source="inboekvariabelen.ProForma"
          label="resources.objections.properties.ProForma"
        />
        <BooleanInput
          source="inboekvariabelen.Ontvankelijk"
          label="resources.objections.properties.Ontvankelijk"
        />
        <BooleanInput
          source="inboekvariabelen.Horen"
          label="resources.objections.properties.Horen"
        />
        <BooleanInput
          source="inboekvariabelen.Taxatieverslag"
          label="resources.objections.properties.Taxatieverslag"
        />
        <BooleanInput
          source="inboekvariabelen.Onkostenvergoeding"
          label="resources.objections.properties.Onkostenvergoeding"
        />
        <BooleanInput
          source="inboekvariabelen.UitstelBetaling"
          label="resources.objections.properties.UitstelBetaling"
        />
        <ArrayInput source="grieven">
          <SimpleFormIterator inline fullWidth>
            <NumberInput label="data.griefid" source="griefid" />
            <TextInput label="data.citaat" source="citaat" helperText={false} />
          </SimpleFormIterator>
        </ArrayInput>
        <SelectInput source="status" choices={choices} />
      </SimpleForm>
    </Edit>
  )
}

export default ObjectionsEdit

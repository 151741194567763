import ListAltIcon from '@mui/icons-material/ListAlt'
import ObjectionsCreate from './Objections.create'
import ObjectionsEdit from './Objections.edit'
import ObjectionsList from './Objections.list'
import ObjectionsShow from './Objections.show'

const resource = {
  list: ObjectionsList,
  create: ObjectionsCreate,
  edit: ObjectionsEdit,
  show: ObjectionsShow,
  icon: ListAltIcon,
}

export default resource

import get from 'lodash/get'
import { RaRecord, useRecordContext, useTranslate } from 'react-admin'

export const HeaderTitle = (props: HeaderTitleProps): JSX.Element => {
  const {
    className,
    i18n,
    record = useRecordContext(),
    source,
  } = props
  const translate = useTranslate()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const getSource = source ? get(record, source) as string : undefined
  return getSource ?
    <span className={className}>{translate(i18n, { name: getSource })}</span> :
    <span className={className}>{translate(i18n)}</span>
}

interface HeaderTitleProps {
  className?: string | undefined;
  i18n: string;
  record?: RaRecord;
  source?: string;
  [key: string]: any;
}

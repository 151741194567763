import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import { Box, Grid, Typography } from '@mui/material'
import { FC } from 'react'
import { useRedirect, useTranslate } from 'react-admin'
import { ObjectionOut, RevisionnOut } from '../../types/apiRequests'

const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp)
  return new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  }).format(date)
}

interface RecentRevisionsProps {
  objections: ObjectionOut[] | undefined
  revisions: RevisionnOut[] | undefined
}

export const RecentRevisionsLogs: FC<RecentRevisionsProps> = ({ objections, revisions }) => {
  const translate = useTranslate()
  const redirect = useRedirect()

  return (
    <>
      <Box>
        <Box
          height='50px'
          display="flex"
          justifyContent='center'
          borderBottom={'1px solid #28282C'}
          p="15px"
        >
          <Typography color='#28282C' variant="h6" fontWeight="bold">
            { translate('data.revisions')}
          </Typography>
        </Box>
      </Box>

      <Grid
        maxHeight='585px'
        overflow='auto'
        sx={{
          '&::-webkit-scrollbar': {
            width: '10px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#DDDDE0',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#31679B',
          },
        }}
      >
        {revisions?.map((revision: RevisionnOut) => {
          const objection = objections?.find(o => o.bezwaarid === revision.objection_id)

          return (
            <Grid
              key={revision.id}
              container
              display="flex"
              direction="row"
              justifyContent="space-between"
              sx={{
                'borderBottom': '2px solid #DDDDE0',
                'flexWrap': 'nowrap',
                'cursor': 'pointer',
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                },
              }}
              onClick={() => redirect('show', 'objection', objection?.id || '')}
            >
              <Box pl="15px" pr="15px" display="flex" alignItems="center" justifyContent="center">
                <AddOutlinedIcon sx={{ color: '#006E5B', fontSize: '22px' }} />
              </Box>

              <Box pt="5px" sx={{ flexGrow: 1, flexBasis: '50%' }}>
                <Typography color='#28282C' variant="body2" fontSize='12px' fontWeight='bold'>
                  { objection?.description }
                </Typography>
                <Typography variant="body2" color='#8E8E93' fontSize='12px'>
                  {objection?.created}
                </Typography>
              </Box>

              <Box p="5px 15px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Typography color='#28282C' variant="body2" fontSize='12px' fontWeight='bold' noWrap>
                  {formatTimestamp(revision.created)}
                </Typography>
              </Box>
            </Grid>
          )})}
      </Grid>
    </>
  )
}

export default RecentRevisionsLogs
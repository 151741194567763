import { Admin as ReactAdmin, CustomRoutes, localStorageStore } from 'react-admin'
import { BrowserRouter, Route } from 'react-router-dom'
import Layout from '../layout/Layout'
import { theme as xxTheme } from '../layout/themes'
import LoginPage from '../pages/Login'
import Unauthorized from '../pages/Unauthorized'
import providers from '../providers'
import { Permissions } from '../types/apiTypes'
import Dashboard from './Dashboard/Dashboard'
import { resourcesForScopes } from './Resources'

const store = localStorageStore()
store.setItem('sidebar.open', true)

const Admin = (): JSX.Element => (
  <BrowserRouter>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore */}
    <ReactAdmin
      dashboard={Dashboard}
      i18nProvider={providers.i18n}
      authProvider={providers.auth()}
      dataProvider={providers.data}
      layout={Layout}
      loginPage={LoginPage}
      disableTelemetry
      theme={xxTheme}
      store={store}
    >
      { (permissions: Permissions): JSX.Element[] => resourcesForScopes(permissions) }
      <CustomRoutes>
        <Route path="/unauthorized" element={<Unauthorized />} />
      </CustomRoutes>
    </ReactAdmin>
  </BrowserRouter>
)

export default Admin

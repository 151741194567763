import { FC } from 'react'
import { EditButton, Show, SimpleShowLayout } from 'react-admin'
import { ActionsToolbar } from '../../components'
import ObjectionsForm from './ObjectionsForm'

const ObjectionsShow: FC = () => (
  <Show actions={<ActionsToolbar ><EditButton variant="contained" label="resources.objections.edit" /></ActionsToolbar>}>
    <SimpleShowLayout>
      <ObjectionsForm />
    </SimpleShowLayout>
  </Show>
)

export default ObjectionsShow

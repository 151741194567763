import jwtDecode from 'jwt-decode'

interface TokenInterface {
    'https://xxllnc.nl/name': string
    'https://xxllnc.nl/organization_name': string
    'https://xxllnc.nl/organization_uuid': string
}

export const storeUserDataInLocalStorage = (token: any) => {
  const decodedToken = jwtDecode<TokenInterface>(token)
  const userName = decodedToken['https://xxllnc.nl/name']
  if (userName) localStorage.setItem('userName', userName)
  const organizationName = decodedToken['https://xxllnc.nl/organization_name']
  if (organizationName) localStorage.setItem('organizationName', organizationName)
  const organizationId = decodedToken['https://xxllnc.nl/organization_uuid']
  if (organizationId) localStorage.setItem('organizationId', organizationId)
}

export const getUserName = () => localStorage.getItem('userName')
export const getOrganizationName = () => localStorage.getItem('organizationName')
export const getOrganizationId = () => localStorage.getItem('organizationId')
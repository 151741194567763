import { ResponsivePie } from '@nivo/pie'
import { FC } from 'react'
import { ObjectionOut } from '../../types/apiRequests'
import { municipalityCodeToName } from '../../utils/municipalityCodes'

interface Props {
  objections: ObjectionOut[] | undefined
}

const ObjectionPerMunicipality: FC<Props> = ({ objections }) => {
  const processData = () => {
    if (!objections) return []

    const perGemeente = objections.reduce((acc, objection) => {
      const { gemeentecode } = objection
      // eslint-disable-next-line security/detect-object-injection
      acc[gemeentecode] = (acc[gemeentecode] || 0) + 1

      return acc
    }, {} as Record<string, number>)

    return Object.entries(perGemeente).map(([key, value]) => {
      const name = municipalityCodeToName(key)
      return ({
        id: name,
        label: name,
        value,
      })})
  }

  const data = processData()

  return (
    <ResponsivePie
      data={data}
      margin={{ top: 60, right: 80, bottom: 60, left: 80 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      colors={{ scheme: 'blues' }}
      borderWidth={1}
      borderColor={{
        from: 'color',
        modifiers: [['darker', 0.2]]
      }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor="#31679B"
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{
        from: 'color',
        modifiers: [
          [
            'darker',
            2
          ]
        ]
      }}
      defs={[]}
      fill={[]}
      legends={[]}
    />
  )
}


export default ObjectionPerMunicipality
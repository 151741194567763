import AssessmentIcon from '@mui/icons-material/Assessment'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import FeedbackIcon from '@mui/icons-material/Feedback'
import HearingIcon from '@mui/icons-material/Hearing'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { Avatar, Box, Grid, styled, Typography } from '@mui/material'
import { FC, ReactNode, useEffect } from 'react'
import { useRefresh, useShowContext, useTranslate } from 'react-admin'
import DownloadPdfButton from '../../components/buttons/DownloadPdfButton'
import { xxllncColor } from '../../layout/themes'
import { Grief, ObjectionOut, ObjectionStatus as Status } from '../../types/apiRequests'
import { dateFormatter } from '../../utils/dateFormatter'
import getInitials from '../../utils/getInitials'
import { municipalityCodeToName } from '../../utils/municipalityCodes'
import ObjectionStatus from './ObjectionStatus'

let refreshTime = 5000

const ObjectionsForm: FC = () => {
  const translate = useTranslate()
  const refresh = useRefresh()
  const { record } = useShowContext<ObjectionOut>()
  const dataNotReadyYet = record?.status === Status.RECEIVED
  const municipality = municipalityCodeToName(record?.gemeentecode)

  useEffect(() => {
    refreshOnReceivedStatus()
  }, [record])

  const refreshOnReceivedStatus = () => {
    setTimeout(() => {
      if (dataNotReadyYet) {
        refreshTime = refreshTime * 2
        refresh()
        refreshOnReceivedStatus()
      }
    }, refreshTime)
  }

  const CheckBox: FC<{ active?: boolean }> = ({ active }) => active ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />

  const Property = ({ title, icon, value }: { title: string, icon: ReactNode, value?: boolean }) => (
    <Grid container>
      <Grid item xs={10} sm={10} md={6} lg={4} >
        <Typography variant="body1">
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: 10, opacity: 0.4 }}>{icon}</span>
            <span>{title}</span>
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={2} sm={2} md={6} lg={8} >
        <CheckBox active={value} />
      </Grid>
    </Grid>
  )

  return (
    <StyledGrid container spacing={2} className={classes.root}>
      <Grid item xs={12} className={classes.item}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5"><span>{ record?.description }</span></Typography>
          <Box className={classes.info}>
            <span><b>{`${translate('data.bezwaarid')}: `}</b>{ record?.bezwaarid }</span>
            { !dataNotReadyYet && <span><b>{`${translate('data.dagtekening')}: `}</b>{ record?.inboekvariabelen?.dagtekening}</span> }
            { dataNotReadyYet && <span>{translate('data.notReadyYet')}</span> }
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Avatar
            variant="circular"
            sx={{ width: 44, height: 44, backgroundColor: '#224463', fontSize: '12pt', fontWeight: 600 }}
          >
            { getInitials(municipality) }
          </Avatar>
          <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', width: '100%' }}>
            <Typography variant="subtitle1">{ municipality }</Typography>
            <Typography variant="body1" sx={{ fontSize: '8pt', marginBottom: '20px' }}>
              {`${translate('data.created')}: ${dateFormatter(record?.created)}`}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography variant="subtitle1" sx={{ marginTop: '20px', marginBottom: '10px' }}>
            <span>{translate('data.properties')}</span>
            { record?.inboekvariabelen && <DownloadPdfButton
              // @ts-expect-error - data is not typed correctly in ObjectionOut
              data={[record.inboekvariabelen]}
              fileName={`${record?.bezwaarid}_${record?.inboekvariabelen?.dagtekening || ''}_metaData`}
            />
            }
          </Typography>
          <Property
            title={translate('resources.objections.properties.ProForma')}
            icon={<ReceiptLongIcon />}
            value={record?.inboekvariabelen?.ProForma}
          />
          <Property
            title={translate('resources.objections.properties.Ontvankelijk')}
            icon={<CheckCircleOutlineIcon />}
            value={record?.inboekvariabelen?.Ontvankelijk}
          />
          <Property
            title={translate('resources.objections.properties.Horen')}
            icon={<HearingIcon />}
            value={record?.inboekvariabelen?.Horen}
          />
          <Property
            title={translate('resources.objections.properties.Taxatieverslag')}
            icon={<AssessmentIcon />}
            value={record?.inboekvariabelen?.Taxatieverslag}
          />
          <Property
            title={translate('resources.objections.properties.Onkostenvergoeding')}
            icon={<MonetizationOnIcon  />}
            value={record?.inboekvariabelen?.Onkostenvergoeding}
          />
          <Property
            title={translate('resources.objections.properties.UitstelBetaling')}
            icon={<PauseCircleOutlineIcon  />}
            value={record?.inboekvariabelen?.UitstelBetaling}
          />
          <ObjectionStatus record={record} />
          <Box className={classes.comments}>
            <Typography variant="subtitle1" sx={{ marginBottom: '10px' }}>
              <span>{translate('data.grieven')}</span>
              { record?.grieven &&
                <DownloadPdfButton
                  // @ts-expect-error - data is not typed correctly in ObjectionOut
                  data={record.grieven}
                  fileName={`${record?.bezwaarid}_${record?.inboekvariabelen?.dagtekening || ''}_grieven`}
                />
              }
            </Typography>
            { record?.grieven?.map((grief: Grief, index: number) => (
              <Box
                key={grief.griefid}
                className={classes.commenRow}
                sx={{ backgroundColor: index % 2 === 0 ? xxllncColor.light80 : 'transparant' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px', width: '100%' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant="body1" sx={{ fontSize: '9pt', whiteSpace: 'pre-line' }}>
                      <Box display="flex" alignItems="center">
                        <FeedbackIcon sx={{ marginRight: '10px' }}/>
                        <span>{ grief.citaat }</span>
                      </Box>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
    </StyledGrid>
  )
}

const PREFIX = 'xxllncTicketsForm'

const classes = {
  root: `${PREFIX}-root`,
  item: `${PREFIX}-item`,
  info: `${PREFIX}-info`,
  comments: `${PREFIX}-comments`,
  commenRow: `${PREFIX}-commenRow`,
}

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`&.${classes.root}`]: {
    [theme.breakpoints.down('md')]: {
      margin: '0 20px',
      width: 'calc(100% - 35px)',
    }
  },
  [`& .${classes.item}`]: {
    paddingLeft: 0,
  },
  [`& .${classes.info}`]: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderBottom: `1px solid ${xxllncColor.dark20}`,
    paddingBottom: '24px',
    marginBottom: '30px',
    marginTop: '16px',
    ['& span']: {
      fontSize: '10pt',
      color: '#6E6E74',
      paddingRight: '20px',
      marginRight: '20px',
      ['&:not(:last-child)']: {
        borderRight: `1px solid ${xxllncColor.dark20}`,
      },
    },
  },
  [`& .${classes.comments}`]: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 0 10px 0',
    width: '100%',
  },
  [`& .${classes.commenRow}`]: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '5px',
    padding: '16px',
  },
}))

export default ObjectionsForm
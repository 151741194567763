import { OrganizationBase } from '../types/apiTypes'
import { getEnvVariable } from '../utils/getEnvVariable'

const tokenName = (): string => getEnvVariable('REACT_APP_AUTH_TOKEN', 'eco_token')
const getToken = (): string | null => localStorage.getItem(tokenName())
const setToken = (token: string): void => localStorage.setItem(tokenName(), `Bearer ${token}`)
const deleteToken = (): void => localStorage.removeItem(tokenName())

const getOrganization = (): {
  appId?: string,
  domain: string,
  organization: OrganizationBase,
} => {
  const appId = localStorage.getItem('appId') ?? ''
  const domain = localStorage.getItem('organization_domain') ?? ''
  const organization: OrganizationBase = {
    auth0Identifier: localStorage.getItem('auth0Identifier') ?? '',
    organizationId: localStorage.getItem('organizationId') ?? '',
  }

  return { organization, domain, appId }
}

const setOrganization = (
  organization: OrganizationBase,
  domain: string,
  appId?: string,
): void => {
  localStorage.setItem('appId', appId ?? '')
  localStorage.setItem('organization_domain', domain ?? '')
  localStorage.setItem('auth0Identifier', organization.auth0Identifier ?? '')
  localStorage.setItem('organizationId', organization.organizationId ?? '')
}

const deleteOrganization = (): void => {
  localStorage.removeItem('appId')
  localStorage.removeItem('organization_domain')
  localStorage.removeItem('auth0Identifier')
  localStorage.removeItem('organizationId')
  localStorage.removeItem('userName')
  localStorage.removeItem('organizationName')
  localStorage.removeItem('organizationId')
}

const setLoginInfo = (
  appId: string,
  email: string,
): void => {
  localStorage.setItem('appId', appId ?? '')
  localStorage.setItem('organization_domain', email ?? '')
}

export default {
  getToken,
  setToken,
  deleteToken,
  getOrganization,
  setOrganization,
  deleteOrganization,
  setLoginInfo
}
